<template>
  <v-dialog :value="value" persistent max-width="400px" transition="dialog-transition">
    <v-card>
      <v-toolbar dark flat color="#1C3144">
        <v-toolbar-title>New TPR SRPs</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container class="pt-0 pb-6 px-6">
        <v-row dense>
          <v-file-input class="mt-7" outlined dense background-color="#fff" v-model="file" prepend-inner-icon="mdi-paperclip" prepend-icon=""
            accept=".xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" chips
            label="Select File for Upload" truncate-length="25" show-size :rules="[v => !!v || 'File is Required']">
          </v-file-input>
        </v-row>
        <v-row dense>
          <v-autocomplete class="mt-7" outlined dense background-color="white" label="Base Ad Group" :items="adgroups"
            item-text="description" return-object v-model="baseGroup" :menu-props="{ maxHeight: 215 }" clearable
            :rules="[v => !!v || 'Base Ad Group is Required']" @click:clear="baseGroup={}" @change="changeBaseGroup"/>
        </v-row>
      </v-container>
      <v-divider/>
      <v-container class="ma-0 px-0" fluid>
        <v-list class="pt-0" style="height:410px; overflow:auto;" color="white">
          <v-list-item-group v-model="selected" active-class="primary--text" multiple>
            <template v-for="(item, index) in groupsInSupplier">
              <v-list-item :key="item.id" :value="item.id" dense>
                <v-list-item-content>
                  <v-list-item-title>
                    {{item.description}}
                  </v-list-item-title>
                </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon color="green">{{selected.includes(item.id) ? 'mdi-check' : ''}}</v-icon>
                  </v-list-item-icon>
              </v-list-item>
              <v-divider v-if="index < groupsInSupplier.length - 1" :key="index"></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
      </v-container>
      <v-divider/>
      <v-toolbar flat>
        <v-spacer/>
        <v-btn :loading="loading" :disabled="!hasRequiredFields" color="#00B24A" class="white--text" @click="uploadFile">
          Upload<v-icon right>mdi-file-upload-outline</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>

  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { notification } from '@/mixins/notification'
import { Storage } from 'aws-amplify'

export default {
  name: 'TPRUpload',

  props: ['value', 'uploadType'],

  mixins: [notification],
  
  data () {
    return {
      file: null,
      baseGroup: null,
      selected: [],
      loading: false
    }
  },

  computed: {
    ...mapState (['user', 'adgroups']),

    hasRequiredFields () {
      return this.file !== null && !this._.isEmpty(this.baseGroup) && this.selected.includes(this.baseGroup.id)
    },

    headers () {
      return [
        { text: !this.baseGroup ? '' : 'Ad Groups', align: 'start', sortable: true, filterable: true, class: 'black--text', value: 'description' }
      ]
    },

    groupsInSupplier () {
      return !this._.isEmpty(this.baseGroup) ? this.adgroups.filter(a => a.supplierCode === this.baseGroup.supplierCode && a.id !== 'ALL') : []
    }
  },

  methods: {
    changeBaseGroup (adgroup) {
      if (!adgroup)  {
        this.baseGroup = {}
        if (this.selected.length) {
          this.selected = []
        }
      } else {
        this.selected.push(adgroup.id)
      }
    },

    async uploadFile () {
      this.loading = true
      Storage.configure({
        AWSS3: {
          bucket: process.env.VUE_APP_FILE_UPLOAD_BUCKET,
          region: 'us-east-1'
        }
      })

      const file = this.file.name.replace(/ /g, '_')

      const filePath = `${this.uploadType.directory}/${file}`
      await Storage.put(filePath, this.file, {
        metadata: {
          user: this.user.username,
          adgroups: this.selected.join(',')
        }
      }).then(() => {
        this.loading = false
        this.notify('success', 'File Successfully Submitted')
      }).catch(() => {
        this.notify('error', 'Upload Attempt Failed')
      })
      this.$emit('refresh')
      this.$emit('update:value', false)
    }
  }
}
</script>
