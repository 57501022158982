var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.value,
        persistent: "",
        "max-width": "400px",
        transition: "dialog-transition"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", flat: "", color: "#1C3144" } },
            [
              _c("v-toolbar-title", [_vm._v("New TPR SRPs")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("update:value", false)
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              )
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "pt-0 pb-6 px-6" },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c("v-file-input", {
                    staticClass: "mt-7",
                    attrs: {
                      outlined: "",
                      dense: "",
                      "background-color": "#fff",
                      "prepend-inner-icon": "mdi-paperclip",
                      "prepend-icon": "",
                      accept:
                        ".xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                      chips: "",
                      label: "Select File for Upload",
                      "truncate-length": "25",
                      "show-size": "",
                      rules: [
                        function(v) {
                          return !!v || "File is Required"
                        }
                      ]
                    },
                    model: {
                      value: _vm.file,
                      callback: function($$v) {
                        _vm.file = $$v
                      },
                      expression: "file"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c("v-autocomplete", {
                    staticClass: "mt-7",
                    attrs: {
                      outlined: "",
                      dense: "",
                      "background-color": "white",
                      label: "Base Ad Group",
                      items: _vm.adgroups,
                      "item-text": "description",
                      "return-object": "",
                      "menu-props": { maxHeight: 215 },
                      clearable: "",
                      rules: [
                        function(v) {
                          return !!v || "Base Ad Group is Required"
                        }
                      ]
                    },
                    on: {
                      "click:clear": function($event) {
                        _vm.baseGroup = {}
                      },
                      change: _vm.changeBaseGroup
                    },
                    model: {
                      value: _vm.baseGroup,
                      callback: function($$v) {
                        _vm.baseGroup = $$v
                      },
                      expression: "baseGroup"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-container",
            { staticClass: "ma-0 px-0", attrs: { fluid: "" } },
            [
              _c(
                "v-list",
                {
                  staticClass: "pt-0",
                  staticStyle: { height: "410px", overflow: "auto" },
                  attrs: { color: "white" }
                },
                [
                  _c(
                    "v-list-item-group",
                    {
                      attrs: { "active-class": "primary--text", multiple: "" },
                      model: {
                        value: _vm.selected,
                        callback: function($$v) {
                          _vm.selected = $$v
                        },
                        expression: "selected"
                      }
                    },
                    [
                      _vm._l(_vm.groupsInSupplier, function(item, index) {
                        return [
                          _c(
                            "v-list-item",
                            {
                              key: item.id,
                              attrs: { value: item.id, dense: "" }
                            },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(" " + _vm._s(item.description) + " ")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-icon",
                                [
                                  _c("v-icon", { attrs: { color: "green" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.selected.includes(item.id)
                                          ? "mdi-check"
                                          : ""
                                      )
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          index < _vm.groupsInSupplier.length - 1
                            ? _c("v-divider", { key: index })
                            : _vm._e()
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-toolbar",
            { attrs: { flat: "" } },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: {
                    loading: _vm.loading,
                    disabled: !_vm.hasRequiredFields,
                    color: "#00B24A"
                  },
                  on: { click: _vm.uploadFile }
                },
                [
                  _vm._v(" Upload"),
                  _c("v-icon", { attrs: { right: "" } }, [
                    _vm._v("mdi-file-upload-outline")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }